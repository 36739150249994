import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
@NgModule({
  imports: [CommonModule, FontAwesomeModule, ProgressSpinnerModule],
  declarations: [LoadingIndicatorComponent],
  exports: [LoadingIndicatorComponent]
})
export class SharedUiLoadingIndicatorModule {}
