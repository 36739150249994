<div
  class="loading-container"
  [style.height]="height"
  [style.width.px]="width"
  [style.align-items]="onTop ? 'flex-start' : 'center'"
  [style.paddingTop]="paddingTop"
  [style.color]="color"
  [style.position]="position"
  [style.top]="top"
  [style.left]="left"
  [ngClass]="{ absoluteCenter: aligning === 'absolute-center' }"
>
  <fa-icon [icon]="loadingIcon" [spin]="true" [ngClass]="{ relativeCenter: aligning === 'absolute-center' }" [style.fontSize]="fontSize"></fa-icon>
</div>
