import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

/**
 * Icon with an animation to represent loading. All the @Input are attaches
 * to the style of the element.
 */
@Component({
  selector: 'ush-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingIndicatorComponent implements OnInit {
  /**
   * @docsNotRequired
   */
  @Input()
  height = 'auto';

  /**
   * @docsNotRequired
   */
  @Input()
  position = 'block';

  /**
   * @docsNotRequired
   */
  @Input()
  width = 'auto';

  /**
   * @docsNotRequired
   */
  @Input()
  aligning = 'none';

  /**
   * @docsNotRequired
   */
  @Input()
  top = '0';

  /**
   * @docsNotRequired
   */
  @Input()
  left = '0';

  /**
   * @docsNotRequired
   */
  @Input()
  fontSize = '16px';

  /**
   * @docsNotRequired
   */
  @Input()
  onTop: boolean;

  /**
   * @docsNotRequired
   */
  @Input()
  color = 'var(--brandColor)';

  /**
   * @docsNotRequired
   */
  @Input()
  paddingTop = '0';

  loadingIcon = faCircleNotch;

  constructor() {}

  /**
   * @docsNotRequired
   */
  ngOnInit(): void {
    this.height = this.fontSize;
  }
}
